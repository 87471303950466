@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

.width-half {
    width: 50%
}

.border-me {
    border: 1px solid #d8d8d8;
}

.sidebar {
    opacity: 1;
}

.enpaged {
    color: red !important;
}

.quietlink {
    text-decoration: none;
    color: inherit !important;
    outline: 0;
}

.archive__item-subtitle {
    font-size: 0.9em;
    font-weight: bold;
    /* border: 1px solid; */
    padding-bottom: 0.5em;
}

.single_title {
    font-size: 0.9em;
    font-weight: bold;
    padding-bottom: 0px;
    margin-bottom: 0px;
    /* border: 1px solid; */
    /* padding-bottom: 0.5em; */
}


.single_subtitle {
    font-size: 0.7em;
    font-weight: bold;
    font-color: blue;
    margin-top: 5px;
    padding-bottom: 0
}

.page__subhead {
    color: #fff;
}

@media screen and (max-width: $large - 1px) { 
    .sidebar img { display: none; }  

}
